import React from "react";
import Carousel from "./carousel";
import "./testimonial.section.css";
import itcimg from "../../images/partner-logos/ITC Hotels.png";
import fortuneimg from "../../images/partner-logos/Fortune Group.png";
import usembassy from "../../images/partner-logos/U.S. Embassy.png";
import oberoiimg from "../../images/partner-logos/The Oberoi.png";
import dpsimg from "../../images/partner-logos/DPS.png";
import emaarimg from "../../images/partner-logos/Emaar MGF Group.png";
import daburimg from "../../images/partner-logos/Dabur.png";
import nbccimg from "../../images/partner-logos/NBCC.png";
import ClientCarousel from "../clientcarousel.component";

const clientImages = [
  { src: itcimg, link: "" },
  { src: fortuneimg, link: "https://www.fortunehotels.in/" },
  { src: usembassy, link: "https://in.usembassy.gov/" },
  { src: oberoiimg, link: "https://www.oberoihotels.com/" },
  { src: dpsimg, link: "http://dpsfamily.org/" },
  { src: emaarimg, link: "https://www.emaar.com/" },
  { src: daburimg, link: "https://www.dabur.com/" },
  { src: nbccimg, link: "https://www.nbccindia.com/" },
];

const carouselSlidesData = [
  {
    content:
      "Schools must provide a variety of spaces for the optimum delivery of education. A&D Studio’s forte lies in developing innovative spaces that foster a positive pedagogical environment for children.",
    author: "Shri V.K. Shunglu, Chairperson",
    source: "DPS Society",
  },
  {
    content:
      "I believe architecture can affect healing outcomes. Incorporating small design decisions can have major health benefits. A&D is the best in implementing a rare aesthetic tailored to the application of the structure.",
    author: "Shri Rajendra Vikhe Patil, Chairperson",
    source: "Pravara Medical Trust",
  },
  {
    content:
      "The best in the country in the execution of key architecture principles involved in K-12 school design, with exquisite attention to details.",
    author: "Mrs. Kamini Bhasin, Principal",
    source: "DPS Noida",
  },
  {
    content:
      "Thanks for the practical approach to producing facilities that meet the evolving needs of educational environments and liveable spaces. They are not afraid to experiment but the adherence to time schedules in delivery is exemplary.",
    author: "Mr. Anil Kumar, Principal",
    source: "DPS Faridabad",
  },
  {
    content:
      "If you are looking for a skilled architecture company with an eye for design that wows, look no further than Tanveer and team.",
    author: "Wing Cr. Ajit Dhokane, CEO",
    source: "Cavok Simulations PVT LTD",
  },
];
export default function Testimonial() {
  return (
    <section id="clients" className="testimonial">
      <h1 className="heading-primary">Testimonials</h1>
      <div className="hr"></div>
      <Carousel slides={carouselSlidesData} />

      <ClientCarousel images={clientImages} />
      <button className="button-dark"> MORE</button>
    </section>
  );
}

// Data for carousel
