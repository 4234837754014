import React, { useState } from "react";
import axios from "axios";
import "./connect.section.scss";
import logo from "../images/A&D Studio Logo_475x310.jpg";
import FormPopUp from "./formpopup";
import _ from "lodash";

export default function Connect() {
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [subject, setsubject] = useState("");
  const [message, setmessage] = useState("");
  const [company, setcompany] = useState("");
  const [errors, setErrors] = useState({});

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showPopup, setshowpopup] = useState(false);

  const onChangefirst_name = (e) => {
    setfirst_name(e.target.value);
    setErrors({ ...errors, [e.target.name]: null });
  };
  const onChangelast_name = (e) => {
    setlast_name(e.target.value);
    setErrors({ ...errors, [e.target.name]: null });
  };
  const onChangeemail = (e) => {
    setemail(e.target.value);
    setErrors({ ...errors, [e.target.name]: null });
  };
  const onChangenumber = (e) => {
    setnumber(e.target.value);
    setErrors({ ...errors, [e.target.name]: null });
  };
  const onChangesubject = (e) => {
    setsubject(e.target.value);
    setErrors({ ...errors, [e.target.name]: null });
  };
  const onChangemessage = (e) => {
    setmessage(e.target.value);
    setErrors({ ...errors, [e.target.name]: null });
  };
  const onChangecompany = (e) => {
    setcompany(e.target.value);
    setErrors({ ...errors, [e.target.name]: null });
  };

  function clearState() {
    setfirst_name("");
    setlast_name("");
    setemail("");
    setsubject("");
    setmessage("");
    setnumber("");
    setcompany("");
  }

  const togglePopup = () => setshowpopup(!showPopup);

  const isEmailCorrect = (email) => {
    // eslint-disable-next-line
    var emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email);
  };

  function onSubmitForm(e) {
    e.preventDefault();
    setIsSubmitting(true);
    const data = {
      first_name,
      last_name,
      email,
      number,
      subject,
      company,
      message,
    };

    let errs = {};

    if (_.isEmpty(first_name)) {
      errs.first_name = "First name is required!";
    }

    if (_.isEmpty(last_name)) {
      errs.last_name = "Last name is required!";
    }

    if (!isEmailCorrect(email)) {
      errs.email = "Email is invalid!";
    }

    if (_.isEmpty(email)) {
      errs.email = "Email is required!";
    }

    if (_.isEmpty(number)) {
      errs.number = "Number is required!";
    }

    if (_.isEmpty(company)) {
      errs.company = "Company is required!";
    }
    if (_.isEmpty(subject)) {
      errs.subject = "Subject is required!";
    }

    if (Object.keys(errs).length > 0) {
      setErrors(errs);
      setIsSubmitting(false);
    } else {
      axios
        .post(
          "https://archdesignsstudio.com/api/public/index.php/connect",
          data
        )
        .then((res) => {
          if (res.status === 200) {
            togglePopup();
            setIsSubmitting(false);
            clearState();
          }
        })
        .catch((err) => {
          console.log(err);
          setIsSubmitting(false);
          clearState();
        });
    }
  }

  return (
    <div>
      <FormPopUp show={showPopup} onClose={togglePopup} />
      <section id="connect" className="connect">
        <div className="row connect__row">
          <div className="col col-12 col-lg-8 connect__leftsection">
            <h1 className="connect__heading">CONNECT</h1>
            <form className="connect__form" onSubmit={onSubmitForm}>
              <div className="row">
                <div className="col col-12 col-lg-6">
                  <label>First Name *</label>
                  <br />
                  <input
                    name="first_name"
                    value={first_name}
                    className={`${errors.first_name && "connect__form__error"}`}
                    onChange={onChangefirst_name}
                  />
                  <small style={{ color: "#DF5555" }}>
                    {errors.first_name}
                  </small>
                </div>
                <div className="col col-12 col-lg-6">
                  <label>Last Name *</label>
                  <br />
                  <input
                    name="last_name"
                    value={last_name}
                    onChange={onChangelast_name}
                    className={`${errors.last_name && "connect__form__error"}`}
                  />
                  <small style={{ color: "#DF5555" }}>{errors.last_name}</small>
                </div>
              </div>

              <div className="row">
                <div className="col col-12 col-lg-6">
                  <label>Email *</label>
                  <br />
                  <input
                    name="email"
                    value={email}
                    onChange={onChangeemail}
                    className={`${errors.email && "connect__form__error"}`}
                  />
                  <small style={{ color: "#DF5555" }}>{errors.email}</small>
                </div>
                <div className="col col-12 col-lg-6">
                  <label>Number *</label>
                  <br />
                  <input
                    name="number"
                    value={number}
                    onChange={onChangenumber}
                    className={`${errors.number && "connect__form__error"}`}
                  />
                  <small style={{ color: "#DF5555" }}>{errors.number}</small>
                </div>
              </div>

              <div className="row">
                <div className="col col-12 col-lg-6">
                  <label>company *</label>
                  <br />
                  <input
                    name="company"
                    value={company}
                    onChange={onChangecompany}
                    className={`${errors.company && "connect__form__error"}`}
                  />
                  <small style={{ color: "#DF5555" }}>{errors.company}</small>
                </div>
                <div className="col col-12 col-lg-6">
                  <label>subject *</label>
                  <br />
                  <input
                    name="subject"
                    value={subject}
                    onChange={onChangesubject}
                    className={`${errors.subject && "connect__form__error"}`}
                  />
                  <small style={{ color: "#DF5555" }}>{errors.subject}</small>
                </div>
              </div>

              <br></br>
              <label>message</label>
              <br />
              <textarea
                name="message"
                value={message}
                onChange={onChangemessage}
              />
              <button className="button-light">
                {!isSubmitting ? "SUBMIT" : "SUBMITTING....."}
              </button>
            </form>
          </div>
          <div className="col col-12 col-lg-4 connect__rightsection">
            <h3 className="connect__heading2">GET IN TOUCH</h3>
            <a className="connect__contact" href="mailto:">
              studio@archdesignsstudio.com
            </a>
            <br />
            <a className="connect__contact" href="">
              +91-011-45520485
            </a>

            <h3 className="connect__heading2">Address</h3>
            {/* <address>
              A&D Studio,
              <br />
              Architecture & Designs Studio,
              <br />
              813, 8th Floor, Ansal Classique Tower,
              <br /> J-Block, Rajouri Garden,
              <br />
              New Delhi 110027
            </address> */}

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3501.717257895436!2d77.1183275150507!3d28.638234990573697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d036a19b2679d%3A0xc6b2110877918b4!2sArchitecture%20%26%20Designs%20Studio!5e0!3m2!1sen!2sin!4v1614859062348!5m2!1sen!2sin"
              width="600"
              height="450"
              style={{ border: 0 }}
              className="connect__map"
              allowfullscreen=""
              loading="lazy"
            ></iframe>

            <img className="connect__logo" src={logo} alt="A&D Logo" />
          </div>
        </div>
      </section>
    </div>
  );
}
