import React, { useState } from "react";
import "./projects.section.scss";
import data from "./projects.data";
import _ from "lodash";
import { Link } from "gatsby";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const tabs = [
  { title: "All" },
  { title: "Institutions", link: "/projects/institutions/" },
  { title: "Commercial", link: "/projects/commercial/" },
  { title: "Residential", link: "/projects/residential/" },
  { title: "Master Planning", link: "/projects/masterplanning/" },
  { title: "Interiors", link: "/projects/interiors/" },
  { title: "Landscape", link: "/projects/landscape/" },
];

function Hex({ imgSrc, alt = "Project Hex", projectname = "", link = "" }) {
  return (
    <li class="hex">
      <div class="hexIn">
        <a class="hexLink" href={link}>
          <LazyLoadImage src={imgSrc} alt={alt} />
          <div className="overlay">
            <h3 className="overlay__text">{projectname}</h3>
          </div>
        </a>
      </div>
    </li>
  );
}

export default function Projects() {
  const [activetab, setactivetab] = useState(tabs[0].title);
  const [showmore, setshowmore] = useState(false);

  let projectsToDisplay = [];

  // if (_.isEqual(activetab, tabs[1].title)) {
  //   projectsToDisplay = data.institutions;
  // } else if (_.isEqual(activetab, tabs[2].title)) {
  //   projectsToDisplay = data.commercial;
  // } else if (_.isEqual(activetab, tabs[3].title)) {
  //   projectsToDisplay = data.residential;
  // } else if (_.isEqual(activetab, tabs[4].title)) {
  //   projectsToDisplay = data.masterplanning;
  // } else if (_.isEqual(activetab, tabs[5].title)) {
  //   projectsToDisplay = data.interiors;
  // } else if (_.isEqual(activetab, tabs[6].title)) {
  //   projectsToDisplay = data.landscape;
  // } else {
  projectsToDisplay = data.all;

  let sorteddata = [];
  // if (!_.isEqual(activetab, tabs[0].title)) {
  //   sorteddata = _.sortBy(projectsToDisplay, [
  //     function (o) {
  //       return o.projectname;
  //     },
  //   ]);
  // } else {
  sorteddata = projectsToDisplay;
  // }
  // console.log("total projects: " + data.all.length);
  function onClickTab(tab) {
    // setactivetab(tab);
    // setshowmore(false);
    if (tab.link) {
      window.location.href = tab.link;
    }
  }

  function toggleShowmore() {
    setshowmore(!showmore);
  }

  let slicenumber = data.all.length;

  if (!showmore) {
    slicenumber = 14;
  } else {
    slicenumber = data.all.length;
  }

  const allhexgons = sorteddata
    .slice(0, slicenumber)
    .map((hex, index) => (
      <Hex
        imgSrc={hex.imgSrc}
        alt={"Project Hex"}
        projectname={hex.projectname}
        link={hex.link}
      />
    ));

  return (
    <section id="projects" className="projects">
      <div className="projects__overlay"></div>
      <h1 className="heading-primary">projects</h1>

      <div className="projects__tabs">
        {tabs.map((item, i) => (
          <div
            className={`projects__tabs__tab ${
              item.title === activetab && " projects__tabs__tab--active"
            }`}
            onClick={() => {
              onClickTab(item);
              // this.props..
            }}
          >
            {item.title !== activetab && (
              <div className="projects__tabs__tab__hovereffect"></div>
            )}
            <div className="projects__tabs__tab__text">{item.title}</div>
          </div>
        ))}
      </div>
      <div className="hr"></div>
      <ul id="hexGrid">{allhexgons}</ul>
      <br />
      {sorteddata.length > 14 && (
        <button onClick={toggleShowmore} className="button-light">
          {!showmore ? "SHOW MORE" : "SHOW LESS"}
        </button>
      )}
    </section>
  );
}
