import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Header from "../components/header";
import Hero from "../components/hero.section";
import About from "../components/about.section";
import Connect from "../components/connect.section";
import Projects from "../components/projects.section";
import Testimonial from "../components/testimonialslider/testimonial.section";

const IndexPage = () => (
  <Layout>
    <SEO
      title="A&D Studio | Award Winning Full-Service Architecture Firm"
      description="As a full-service architecture firm for homeowners, builders and designers looking for the highest standards in architectural work, we offer bespoke solutions for every taste, scale and specification."
      img="https://archdesignsstudio.com/assets/linkpreview.png"
    />
    <Header />
    <Hero />
    <About />
    <Projects />
    <Testimonial />
    <Connect />
  </Layout>
);

export default IndexPage;
