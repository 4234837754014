import React from "react";
import "./style.scss";

function FormPopUp({ show, onClose }) {
  return (
    <div className={`form-popup ${show && " form-popup--show"}`}>
      <div className="form-popup__design1"></div>
      <div className="form-popup__design2"></div>
      <div className="form-popup__text">
        Thank you. Your response have been recorded.
        <br />
      </div>
      <button className="button-dark" onClick={onClose}>
        Close
      </button>
    </div>
  );
}

FormPopUp.defaultProps = {
  show: false,
  onClose: () => {},
};

export default FormPopUp;
