import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";

import "slick-carousel/slick/slick-theme.css";

import "./clientcarousel.component.css";

export default function ClientCarousel({ images = [] }) {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    // fade: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,

    cssEase: "linear",

    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const logos = images.map((img) => (
    <div className="clientcarousel__item">
      <a target="__blank" href={img.link}>
        <img alt="Client Logo" src={img.src} />
      </a>
    </div>
  ));
  return (
    <Slider className="clientcarousel" {...settings}>
      {logos}
    </Slider>
  );
}
