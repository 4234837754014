import React, { useEffect, useState, useRef } from "react";
import "./about.section.scss";
import placeholdericon from "../images/website-optimization-2-128.png";
import archicon from "../images/A&D_Icon-02.png";
import engineeringicon from "../images/A&D_Icon-04.png";
import planningicon from "../images/A&D_Icon-01.png";
import interioricon from "../images/A&D_Icon-03.png";
import landscapeicon from "../images/A&D_Icon-05.png";
import { Link } from "gatsby";

export default function About() {
  // var isFactsShown = false;

  // var countries = 0;
  // var cities;

  const [countries, setCountries] = useState(0); // 3
  const [cities, setCities] = useState(0); // 25
  const [awards, setAwards] = useState(0); // 5
  const [area, setArea] = useState(0); // 20m+

  const [isFactsShown, setIsFactShown] = useState(false);

  const incrementCoutries = () => {
    if (countries <= 2) {
      setCountries((prevCount) => prevCount + 1);
    }
  };

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (countries <= 3) {
  //       console.log(countries);
  //       setCountries((prevCount) => prevCount + 1);
  //     } else {
  //       clearInterval(interval);
  //       return;
  //     }

  //     return () => clearInterval(interval);
  //   }, 400);
  // }, [countries]);

  // import React, { useState, useEffect, useRef } from "react";

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  const incrementCities = () => {
    if (cities <= 24) {
      setCities(cities + 1);
    }
  };
  const incrementAwards = () => {
    if (awards <= 4) setAwards(awards + 1);
  };
  const incrementArea = () => {
    if (area <= 19) setArea(area + 1);
  };

  useEffect(() => {
    const facts = document.querySelector("#about-facts");

    document.addEventListener("scroll", () => {
      if (!isFactsShown) {
        if (isInViewport(facts)) {
          setIsFactShown(true);

          // isFactsShown = true;
        }
      }
    });
  }, []);

  useInterval(() => {
    if (isFactsShown) {
      incrementCoutries();
      incrementCities();
      incrementArea();
      incrementAwards();
    }
  }, 70);

  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.top <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  return (
    <section id="about" className="about">
      <h3 className="about__heading1">ABOUT</h3>
      <h1 className="about__heading2">A&D Studio</h1>

      <div class="service__container">
        <div className="row service__container__toprow">
          <div className="col col-12 col-lg-4">
            <div className="service__card">
              <img src={planningicon} />
              <h4 className="service__heading">Master Planning</h4>
            </div>
          </div>
          <div className="col col-12 col-lg-4">
            <div className="service__card">
              <img src={archicon} />
              <h4 className="service__heading">Architecture</h4>
            </div>
          </div>
          <div className="col col-12 col-lg-4">
            <div className="service__card">
              <img src={interioricon} />
              <h4 className="service__heading">Interiors</h4>
            </div>
          </div>
        </div>
        <div className="row service__container__bottomrow">
          <div className="col col-12 col-lg-6">
            <div className="service__card">
              <img src={engineeringicon} />
              <h4 className="service__heading">Engineering</h4>
            </div>
          </div>
          <div className="col col-12 col-lg-6">
            <div className="service__card">
              <img src={landscapeicon} />
              <h4 className="service__heading">Landscape</h4>
            </div>
          </div>
        </div>
      </div>
      <p className="about__text">
        Architecture & Design Studios is an award-winning, multi-disciplinary
        firm with its headquarters in Delhi, India. We believe infrastructure
        done well is access, opportunity and unlimited potential. <br />
        <br />
        As a full-service architecture firm for homeowners, builders and
        designers looking for the highest standards in architectural work, we
        offer bespoke solutions for every taste, scale and specification. Our
        processes are geared to be unique and cost-effective- and are aimed at
        solving complex problems through seamless design.
        <br />
        <br />
        Our team consists of some of the top urban design professionals in the
        country, with wide experience in institutional practices, commercial and
        residential work. It leverages its collective design experience and
        knowledge to offer an integrated approach and deliver well-calibrated
        design solutions across building types PAN India and abroad.
        <br />
        <br />
        Our Founder & Principal Architect, Mr. Tanveer Chadha, is at the helm of
        affairs with over two decades of experience and expertise at the
        forefront of the field. He has been a pioneer in designing & executing
        projects and well-detailed builds across industries. As an active member
        of the architecture community, Mr Tanveer Chadha sits on several design
        advisory panels and committees for various agencies and design
        institutions.
        <br />
        <br />
        Our clients in the public and private sector trust us to deliver
        innovative solutions that push the limits of what exists already and
        what is possible - to create infrastructure that not only lives,
        breathes and supports purpose but inspires every day. We build legacies
        of sustainable infrastructure for generations to come. As a premier
        infrastructure firm, delivering professional services throughout the
        project lifecycle, we are proud to be building a better world every day.
      </p>

      <br />
      <Link to="/team" className="button-light mr-sm">
        TEAM
      </Link>
      <Link to="/milestones" className="button-light mr-sm">
        MILESTONES
      </Link>
      <a
        target="__blank"
        href="https://archdesignsstudio.com/assets/docs/companyprofile.pdf"
        className="button-light"
      >
        COMPANY PROFILE
      </a>
      <br />
      <br />
      <div id="about-facts" className="about__facts">
        <div className="row about__facts__row">
          <div className="col col-12 col-md-6 col-lg-3">
            <div className="about__facts__fact">
              <div className="about__facts__fact__figures">{countries}</div>
              <div className="about__facts__fact__title">Countries</div>
            </div>
          </div>
          <div className="col col-12 col-md-6 col-lg-3">
            <div className="about__facts__fact">
              <div className="about__facts__fact__figures">{cities}+</div>
              <div className="about__facts__fact__title">Cities</div>
            </div>
          </div>
          <div className="col col-12 col-md-6 col-lg-3">
            <div className="about__facts__fact">
              <div className="about__facts__fact__figures">{awards}+</div>
              <div className="about__facts__fact__title">Awards</div>
            </div>
          </div>
          <div className="col col-12 col-md-6 col-lg-3">
            <div className="about__facts__fact">
              <div className="about__facts__fact__figures">{area}M+ </div>
              <div className="about__facts__fact__title">AREA (Sq. Ft.)</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
