import React from "react";
// import video from "../videos/Building Footage.mp4"
import "./hero.section.css";

const video = "https://archdesignsstudio.com/assets/video/Building_Footage.mp4";
export default function Hero() {
  return (
    <section className="hero" id="hero">
      <video
        className="hero__video"
        autoPlay
        muted
        playsInline
        loop
        // src="https://res.cloudinary.com/dkhomrypx/video/upload/v1604995861/BUILDING_CONSTRUCTION_Normal_ae8uht.webm"
        src={video}
      />

      <div className="hero__overlay">
        <h1 className="hero__heading">
          TURNING
          <br />
          DREAMS
          <br /> INTO
          <br /> REALITY
        </h1>
      </div>
    </section>
  );
}
